import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6938d8e1"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "localized-textarea" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Textarea = _resolveComponent("Textarea");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    const _directive_localized_validation = _resolveDirective("localized-validation");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppForm, {
            modelValue: _ctx.modelLocalized,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.modelLocalized) = $event))
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, { class: "col-xl-7" }, {
                    default: _withCtx(() => [
                        _withDirectives(_createVNode(_component_Textarea, {
                            modelValue: _ctx.model,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                            name: "value",
                            disabled: _ctx.disabled,
                            placeholder: _ctx.placeholder,
                            autoResize: true,
                            rows: "2",
                            cols: "30"
                        }, null, 8, ["modelValue", "disabled", "placeholder"]), [
                            [_directive_localized_validation]
                        ]),
                        (!_ctx.disabled)
                            ? (_openBlock(), _createBlock(_component_FormError, {
                                key: 0,
                                name: "value"
                            }))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["modelValue"])
    ]));
}
