import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { parseTemplate } from "@/app/translation/functions/parse-template.function";
import { renderTemplate } from "@/app/translation/functions/render-template.function";
let TemplateService = class TemplateService {
    eval(template, args) {
        if (!template) {
            return undefined;
        }
        try {
            const parsed = parseTemplate(template);
            return renderTemplate(parsed, args);
        }
        catch (e) {
            return undefined;
        }
    }
};
TemplateService = __decorate([
    service()
], TemplateService);
export { TemplateService };
