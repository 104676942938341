import { createModule } from "@/di/module";
import { SideNavigationService } from "@/app/core/services/side-navigation.service";
import { ConsoleErrorHandlerService } from "@/app/core/services/console-error-handler.service";
import { ApiErrorInterceptorService } from "@/app/core/services/api-error-interceptor.service";
import { MaintenanceInterceptorService } from "@/app/core/services/maintenance-interceptor.service";
import { ContextMenuService } from "@/app/core/services/context-menu.service";
import { CookieService } from "@/app/core/services/cookie.service";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { CancelEditModalId, ChangeLanguageModalId, ConfirmDeleteModalId, CookieSettingsModalId, DeleteRestrictionModalId, SubModuleInfoModalId, } from "@/app/core/core.constants";
import SideNavigationContextMenuComponent from "@/app/core/components/side-navigation/side-navigation-context-menu.component.vue";
import CancelEditModalComponent from "@/app/core/components/cancel-edit-modal.component.vue";
import ConfirmDeleteModalComponent from "@/app/core/components/confirm-delete-modal.component.vue";
import LogoComponent from "@/app/core/components/logo.component.vue";
import SideNavigationComponent from "@/app/core/components/side-navigation/side-navigation.component.vue";
import TopNavigationComponent from "@/app/core/components/top-navigation.component.vue";
import CookieBannerComponent from "@/app/core/components/cookies/cookie-banner.component.vue";
import CookieSettingsModalComponent from "@/app/core/components/cookies/cookie-settings-modal.component.vue";
import ToasterComponent from "@/app/core/components/toaster.component.vue";
import MenuItemComponent from "@/app/shared/components/menu-item.component.vue";
import ChangeLanguageModalComponent from "@/app/core/components/change-language-modal.component.vue";
import DeleteRestrictionModalComponent from "@/app/core/components/delete-restriction-modal.component.vue";
import SubModuleInfoModalComponent from "@/app/shared/components/sub-module-info-modal.component.vue";
import AttachmentWarningModalComponent from "@/app/shared/components/attachment-widget/attachment-warning-modal.component.vue";
import VerifyUserModalComponent from "@/app/users/components/verify-user-modal.component.vue";
import { HttpRequestInterceptorToken } from "@/app/http/tokens/http-request-interceptor.token";
import { RequestBatchingInterceptorService } from "@/app/core/services/request-batching-interceptor.service";
import { CompanyIdHeaderInterceptorService } from "@/app/core/services/company-id-header-interceptor.service";
import { HttpResponseInterceptorToken } from "@/app/http/tokens/http-response-interceptor.token";
import { HttpErrorInterceptorToken } from "@/app/http/tokens/http-error-interceptor.token";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { RolesService } from "@/app/shared/services/roles.service";
import { AppInitServiceToken } from "@/app/shared/tokens/app-init-service.token";
import { ApiSubscriptionKeyInterceptorService } from "@/app/http/services/api-subscription-key-interceptor.service";
import { CompanyAppInitService } from "@/app/core/services/company-app-init.service";
import { VerifyUserModalId } from "@/app/users/users.constants";
import { AttachmentWarningModalId } from "@/app/shared/components/attachment-widget/attachment.constants";
export const CoreModule = createModule({
    components: [
        CancelEditModalComponent,
        ChangeLanguageModalComponent,
        ConfirmDeleteModalComponent,
        SideNavigationContextMenuComponent,
        LogoComponent,
        MenuItemComponent,
        SideNavigationComponent,
        ToasterComponent,
        TopNavigationComponent,
        CookieBannerComponent,
        DeleteRestrictionModalComponent,
        SubModuleInfoModalComponent,
        VerifyUserModalComponent,
    ],
    imports: [
        ModalModule(CancelEditModalId, CancelEditModalComponent),
        ModalModule(AttachmentWarningModalId, AttachmentWarningModalComponent),
        ModalModule(ChangeLanguageModalId, ChangeLanguageModalComponent),
        ModalModule(ConfirmDeleteModalId, ConfirmDeleteModalComponent),
        ModalModule(CookieSettingsModalId, CookieSettingsModalComponent),
        ModalModule(DeleteRestrictionModalId, DeleteRestrictionModalComponent),
        ModalModule(SubModuleInfoModalId, SubModuleInfoModalComponent),
        ModalModule(VerifyUserModalId, VerifyUserModalComponent),
    ],
    providers: [
        SideNavigationService,
        ApiErrorInterceptorService,
        MaintenanceInterceptorService,
        ConsoleErrorHandlerService,
        ContextMenuService,
        RequestBatchingInterceptorService,
        CompanyIdHeaderInterceptorService,
        ApiSubscriptionKeyInterceptorService,
        CookieService,
        RolesService,
        { token: AppInitServiceToken, useClass: CompanyAppInitService },
        { token: HttpRequestInterceptorToken, useToken: RequestBatchingInterceptorService },
        { token: HttpResponseInterceptorToken, useToken: RequestBatchingInterceptorService },
        { token: HttpErrorInterceptorToken, useToken: ApiErrorInterceptorService },
        { token: HttpErrorInterceptorToken, useToken: MaintenanceInterceptorService },
        { token: HttpErrorInterceptorToken, useToken: RequestBatchingInterceptorService },
        { token: TranslationPrefixToken, useValue: "core" },
    ],
});
