import { renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a1a0fb74"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "menu-items" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OverlayPanel = _resolveComponent("OverlayPanel");
    return (_openBlock(), _createElementBlock("div", {
        class: "dropdown-menu-overlay",
        onClick: _cache[2] || (_cache[2] = _withModifiers(
        //@ts-ignore
        (...args) => (_ctx.toggle && _ctx.toggle(...args)), ["stop"]))
    }, [
        _renderSlot(_ctx.$slots, "button", {}, undefined, true),
        _createVNode(_component_OverlayPanel, {
            ref: "overlayPanel",
            onHide: _cache[0] || (_cache[0] = ($event) => (_ctx.isVisible = false)),
            onShow: _cache[1] || (_cache[1] = ($event) => (_ctx.isVisible = true))
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", {
                    class: _normalizeClass(["overlay-panel-content", _ctx.overlayClass])
                }, [
                    _createElementVNode("div", _hoisted_1, [
                        _renderSlot(_ctx.$slots, "items", _normalizeProps(_guardReactiveProps({ hideOverlay: _ctx.hide })), undefined, true)
                    ])
                ], 2)
            ]),
            _: 3
        }, 512)
    ]));
}
