import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Dropdown = _resolveComponent("Dropdown");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        class: "change-language-modal p-fluid",
        header: _ctx.translate('header'),
        showCloseIcon: false
    }, {
        default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.translate("message")), 1),
            _createVNode(_component_Dropdown, {
                class: "mb-4",
                options: _ctx.languages,
                optionValue: "value",
                optionLabel: "label",
                modelValue: _ctx.selectedLangauge,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.selectedLangauge) = $event)),
                translateKey: "languages",
                showClear: false
            }, null, 8, ["options", "modelValue"]),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        class: "p-button-tertiary",
                        onClick: _ctx.close
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.cancel")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_AppButton, {
                        onClick: _ctx.changeLanguage,
                        loading: _ctx.isChanging
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.confirm")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick", "loading"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
