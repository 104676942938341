var _a, _b, _c, _d, _e;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import fallback from "@/assets/i18n/en.yml";
import _ from "lodash";
import { reactive } from "@/di/decorators/computed.decorator";
import { UserStorageService } from "@/app/shared/services/user-storage.service";
import { TemplateService } from "@/app/translation/services/template.service";
import { inject } from "@/di/decorators/inject.decorator";
import { TranslationCache } from "@/app/translation/translation.cache";
import { TranslationCacheToken } from "@/app/translation/tokens/translation-cache.token";
import { TranslateFunctionFactoryToken } from "@/app/translation/tokens/translate-function-factory.token";
import { TranslateFunctionFactory } from "@/app/translation/factories/translate-function.factory";
import { supportedLanguages } from "@/app/translation/translation.constants";
const navigatorLanguage = navigator.language?.slice(0, 2);
const defaultLanguage = supportedLanguages.includes(navigatorLanguage) ? navigatorLanguage : "en";
let TranslationService = class TranslationService {
    userStorageService;
    templateService;
    cache;
    translations = {};
    currentLanguage = defaultLanguage;
    translateFunction;
    constructor(userStorageService, templateService, cache, translateFunctionFactory) {
        this.userStorageService = userStorageService;
        this.templateService = templateService;
        this.cache = cache;
        this.translateFunction = translateFunctionFactory(templateService, cache);
    }
    async onAppInit() {
        await this.loadTranslations(this.getCurrentLanguage());
        try {
            this.restoreSelectedLanguage();
        }
        catch (e) {
            // do nothing
        }
    }
    async loadTranslations(language) {
        if (process.env.VUE_APP_LOAD_TRANSLATIONS === "false") {
            return;
        }
        if (_.has(this.translations, language)) {
            this.setLanguage(language);
            return;
        }
        try {
            const urlPath = `/translations/LOADYFRONTEND.${language.toUpperCase()}.json`;
            const url = new URL(urlPath, process.env.VUE_APP_TRANSLATIONS_BASE_URL);
            const response = await fetch(url).then((res) => res.json());
            _.set(this.translations, language, response);
            this.setLanguage(language);
        }
        catch (e) {
            // do nothing
        }
    }
    restoreSelectedLanguage() {
        this.setLanguage(this.userStorageService.get("language") ?? defaultLanguage);
    }
    setLanguage(language) {
        this.cache.reset();
        this.currentLanguage = language;
        this.userStorageService.set("language", language);
    }
    getLanguage() {
        return this.currentLanguage;
    }
    getSupportedLanguages() {
        return supportedLanguages;
    }
    getTranslations() {
        return this.translations[this.currentLanguage] ?? fallback;
    }
    getCurrentLanguage() {
        return this.userStorageService.get("language") ?? defaultLanguage;
    }
    translate(key, params = {}) {
        const translations = this.getTranslations();
        return this.translateFunction(translations, key, params);
    }
};
__decorate([
    reactive(),
    __metadata("design:type", typeof (_e = typeof Record !== "undefined" && Record) === "function" ? _e : Object)
], TranslationService.prototype, "translations", void 0);
TranslationService = __decorate([
    service(),
    __param(2, inject(TranslationCacheToken)),
    __param(3, inject(TranslateFunctionFactoryToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof UserStorageService !== "undefined" && UserStorageService) === "function" ? _a : Object, typeof (_b = typeof TemplateService !== "undefined" && TemplateService) === "function" ? _b : Object, typeof (_c = typeof TranslationCache !== "undefined" && TranslationCache) === "function" ? _c : Object, typeof (_d = typeof TranslateFunctionFactory !== "undefined" && TranslateFunctionFactory) === "function" ? _d : Object])
], TranslationService);
export { TranslationService };
