import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1e53c981"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "d-flex flex-row h-100 align-items-center gap-3 px-4" };
const _hoisted_2 = { class: "d-flex flex-column gap-2" };
const _hoisted_3 = { class: "title" };
const _hoisted_4 = { class: "text" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_LinkButton = _resolveComponent("LinkButton");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        class: "attachment-warning-modal",
        showCloseIcon: true,
        showHeader: false
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_Icon, {
                    class: "close-modal",
                    name: "close",
                    onClick: _ctx.close
                }, null, 8, ["onClick"]),
                _createVNode(_component_Icon, {
                    class: "warning",
                    variant: "rounded",
                    name: "warning"
                }),
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.translate("header")), 1),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.translate("message")), 1),
                    _createVNode(_component_LinkButton, { onClick: _ctx.changeLanguage }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("link")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"])
                ])
            ])
        ]),
        _: 1
    }, 8, ["modal-id"]));
}
