import { WordType } from "@/app/translation/types/word-type.type";
import _ from "lodash";
import { Operator } from "@/app/translation/types/operator.type";
const stringCharacters = ['"', "`"];
export const parseTemplate = (template) => {
    let index = 0;
    const words = [];
    const context = {
        dynamicKey: false,
        function: false,
        functionArg: false,
        string: false,
        variable: false,
        wordBuffer: {
            type: WordType.Text,
            start: 0,
            end: 0,
            raw: "",
        },
        stringCharacter: "",
    };
    const changeWordBufferType = (type) => {
        const word = words[words.length - 1];
        const buffered = _.clone(context.wordBuffer);
        if (buffered.end - buffered.start > 0) {
            words.push(buffered);
        }
        context.wordBuffer.start = word.end;
        context.wordBuffer.end = word.end;
        context.wordBuffer.type = type;
        context.wordBuffer.raw = "";
    };
    const updateContext = () => {
        const word = words[words.length - 1];
        if (!word) {
            return;
        }
        if (word.type === WordType.VariableStart && !context.variable) {
            context.variable = true;
            changeWordBufferType(WordType.Key);
        }
        if (!context.variable) {
            return;
        }
        if (word.type === WordType.VariableEnd) {
            context.variable = false;
            context.dynamicKey = false;
            context.function = false;
            context.functionArg = false;
            context.string = false;
            changeWordBufferType(WordType.Text);
        }
        if (word.type === WordType.DynamicKeyStart && !context.dynamicKey) {
            context.dynamicKey = true;
            changeWordBufferType(WordType.DynamicKey);
        }
        if (word.type === WordType.DynamicKeyEnd && context.dynamicKey) {
            context.dynamicKey = false;
            changeWordBufferType(WordType.Key);
        }
        if (word.type == WordType.StringStart && !context.string) {
            context.string = true;
            context.stringCharacter = word.raw;
            changeWordBufferType(WordType.String);
        }
        if (word.type == WordType.StringEnd && context.string) {
            context.string = false;
            context.stringCharacter = "";
            changeWordBufferType(WordType.Key);
        }
        if (word.type == WordType.Pipe) {
            context.function = true;
            changeWordBufferType(WordType.FunctionName);
        }
        if (word.type == WordType.FunctionArgStart && context.function) {
            context.functionArg = true;
            changeWordBufferType(WordType.FunctionArg);
        }
        if (word.type == WordType.FunctionArgEnd && context.functionArg) {
            context.functionArg = false;
            changeWordBufferType(WordType.Null);
        }
        if (word.type == WordType.FunctionArgSeparator && context.functionArg) {
            changeWordBufferType(WordType.FunctionArg);
        }
    };
    parseLoop: while (index <= template.length) {
        updateContext();
        const operatorKeys = _.keys(Operator);
        const char = template.slice(index, index + 1);
        if (!context.string && stringCharacters.includes(char)) {
            words.push({
                type: WordType.StringStart,
                start: index,
                end: index + 1,
                raw: char,
            });
            index += 1;
            continue;
        }
        if (context.string && char === context.stringCharacter) {
            words.push({
                type: WordType.StringEnd,
                start: index,
                end: index + 1,
                raw: char,
            });
            index += 1;
            continue;
        }
        for (const key of operatorKeys) {
            if (context.string) {
                break;
            }
            const value = _.get(Operator, key);
            if (template.slice(index, index + value.length) === value) {
                const type = _.get(WordType, key);
                if (!context.variable && type !== WordType.VariableStart) {
                    break;
                }
                words.push({
                    type: _.get(WordType, key),
                    raw: value,
                    start: index,
                    end: index + value.length,
                });
                index += value.length;
                continue parseLoop;
            }
        }
        context.wordBuffer.raw += template.slice(index, index + 1);
        context.wordBuffer.end = index + 1;
        index++;
    }
    words.push(context.wordBuffer);
    return words.sort((a, b) => a.start - b.start);
};
