var _a, _b, _c, _d, _e, _f;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { DomainService } from "@/app/modular-content/services/domain.service";
import { error } from "@/app/shared/functions/error";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { reactive } from "@/di/decorators/computed.decorator";
import { inject } from "@/di/decorators/inject.decorator";
import { ToastService } from "@/app/shared/services/toast.service";
import { ToastSeverity } from "@/app/shared/entities/toast-message";
import { DomainMetadata } from "@/app/modular-content/type/domain-metadata.type";
import { DomainMetadataToken } from "@/app/modular-content/token/modular-content.token";
import { TranslationService } from "@/app/translation/services/translation.service";
import { RouterService } from "@/app/shared/services/router.service";
import { AssignedProductClustersListId } from "@/app/domains/assigned-product-clusters/assigned-product-cluster.constants";
import { AssignedProductsListId } from "@/app/domains/assigned-products/assigned-products.constants";
import { RouteMetaListId } from "@/app/modular-content/modular-content.constants";
const toastTranslationKey = {
    [AssignedProductsListId]: "domains.assigned-products.services.assigned-products.toast-messages",
    [AssignedProductClustersListId]: "domains.assigned-clusters.services.assigned-clusters.toast-messages",
};
const dtoKey = {
    [AssignedProductsListId]: "productIds",
    [AssignedProductClustersListId]: "productClusterIds",
};
let AssignedEntitiesService = class AssignedEntitiesService {
    domainService;
    routerService;
    metaData;
    httpClient;
    toastService;
    translationService;
    assignments = [];
    toastTranslationKey = "";
    listId = "";
    baseUrlCallback;
    constructor(domainService, routerService, metaData, httpClient, toastService, translationService) {
        this.domainService = domainService;
        this.routerService = routerService;
        this.metaData = metaData;
        this.httpClient = httpClient;
        this.toastService = toastService;
        this.translationService = translationService;
        this.baseUrlCallback = metaData.baseUrlCallback;
    }
    async assign(entities) {
        this.listId = this.getListId();
        const key = dtoKey[this.listId];
        await this.httpClient.post(this.getBaseUrl() ?? error(), {
            [key]: entities.map((it) => it.id),
        });
        this.displaySuccessToast("assigned", this.countAssigned(entities));
        this.displaySuccessToast("unassigned", this.countUnassigned(entities));
    }
    getListId() {
        return this.routerService.getMeta(RouteMetaListId);
    }
    countAssigned(entities) {
        const assignedIds = this.assignments.map((it) => it.id);
        return entities.filter(({ id }) => !assignedIds.includes(id)).length;
    }
    countUnassigned(entities) {
        const assignedIds = this.assignments.map((it) => it.id);
        const updateIds = entities.map((it) => it.id);
        return assignedIds.filter((id) => !updateIds.includes(id)).length;
    }
    displaySuccessToast(what, count) {
        this.toastTranslationKey = toastTranslationKey[this.listId];
        if (count === 0) {
            return;
        }
        const plurality = count === 1 ? "single" : "multiple";
        const prefix = `${this.toastTranslationKey}`;
        this.toastService.showToast({
            severity: ToastSeverity.Success,
            message: this.translationService.translate(`${prefix}.${what}.${plurality}`, { count }),
        });
    }
    getBaseUrl() {
        return this.baseUrlCallback(this.routerService);
    }
};
__decorate([
    reactive(),
    __metadata("design:type", Array)
], AssignedEntitiesService.prototype, "assignments", void 0);
AssignedEntitiesService = __decorate([
    service(),
    __param(2, inject(DomainMetadataToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof DomainService !== "undefined" && DomainService) === "function" ? _a : Object, typeof (_b = typeof RouterService !== "undefined" && RouterService) === "function" ? _b : Object, typeof (_c = typeof DomainMetadata !== "undefined" && DomainMetadata) === "function" ? _c : Object, typeof (_d = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _d : Object, typeof (_e = typeof ToastService !== "undefined" && ToastService) === "function" ? _e : Object, typeof (_f = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _f : Object])
], AssignedEntitiesService);
export { AssignedEntitiesService };
