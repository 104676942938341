var _a, _b;
import { __decorate, __metadata, __param } from "tslib";
import { inject } from "@/di/decorators/inject.decorator";
import { service } from "@/di/decorators/service.decorator";
import { DomainService } from "@/app/modular-content/services/domain.service";
import { removeDomainBaseUrlParams } from "@/app/modular-content/modules/functions/domain-base-url-params.function";
import { DomainMetadata } from "@/app/modular-content/type/domain-metadata.type";
import { DomainMetadataToken } from "@/app/modular-content/token/modular-content.token";
let SearchUrlFactoryService = class SearchUrlFactoryService {
    domainMetadata;
    domainService;
    constructor(domainMetadata, domainService) {
        this.domainMetadata = domainMetadata;
        this.domainService = domainService;
    }
    createUrl() {
        return `${this.getBaseUrl()}/search`;
    }
    getBaseUrl() {
        return this.shouldKeepUrlParams() ? this.getDomainBaseUrl() : this.getDomainBaseUrlWithoutParams();
    }
    shouldKeepUrlParams() {
        return this.domainMetadata.keepSearchUrlParams;
    }
    getDomainBaseUrl() {
        return this.domainService.getBaseUrl();
    }
    getDomainBaseUrlWithoutParams() {
        return removeDomainBaseUrlParams(this.getDomainBaseUrl());
    }
};
SearchUrlFactoryService = __decorate([
    service(),
    __param(0, inject(DomainMetadataToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof DomainMetadata !== "undefined" && DomainMetadata) === "function" ? _a : Object, typeof (_b = typeof DomainService !== "undefined" && DomainService) === "function" ? _b : Object])
], SearchUrlFactoryService);
export { SearchUrlFactoryService };
