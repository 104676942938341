export const applyDomainBaseUrlParams = (url, params) => {
    return transformParamsInUrl(url, (param) => params[param] ?? "");
};
export const removeDomainBaseUrlParams = (url) => {
    return transformParamsInUrl(url, () => "");
};
function transformParamsInUrl(url, transformParam) {
    const segmentSeparator = "/";
    const paramMarker = ":";
    const isParam = (segment) => segment.startsWith(paramMarker);
    const removeParamMarker = (segment) => segment.replace(paramMarker, "");
    return url
        .split(segmentSeparator)
        .map((segment) => (isParam(segment) ? transformParam(removeParamMarker(segment)) : segment))
        .filter((segment) => segment.length > 0)
        .join(segmentSeparator);
}
