export var Operator;
(function (Operator) {
    Operator["VariableStart"] = "{{";
    Operator["VariableEnd"] = "}}";
    Operator["DynamicKeyStart"] = "${";
    Operator["DynamicKeyEnd"] = "}";
    Operator["Pipe"] = "|";
    Operator["FunctionArgStart"] = "(";
    Operator["FunctionArgEnd"] = ")";
    Operator["FunctionArgSeparator"] = ",";
})(Operator || (Operator = {}));
