export var WordType;
(function (WordType) {
    WordType["Null"] = "null";
    WordType["VariableStart"] = "variable-start";
    WordType["VariableEnd"] = "variable-end";
    WordType["DynamicKeyStart"] = "dynamic-key-start";
    WordType["DynamicKey"] = "dynamic-key";
    WordType["DynamicKeyEnd"] = "dynamic-key-end";
    WordType["Key"] = "key";
    WordType["Pipe"] = "operator-pipe";
    WordType["FunctionName"] = "function-name";
    WordType["FunctionArgStart"] = "function-arg-start";
    WordType["FunctionArgEnd"] = "function-arg-end";
    WordType["FunctionArg"] = "function-arg";
    WordType["FunctionArgSeparator"] = "function-arg-separator";
    WordType["StringStart"] = "string-start";
    WordType["String"] = "string";
    WordType["StringEnd"] = "string-end";
    WordType["Text"] = "text";
})(WordType || (WordType = {}));
