import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-04a09f66"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "description" };
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = { class: "connection-info" };
const _hoisted_4 = { class: "connection-info-text" };
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = { key: 1 };
const _hoisted_7 = { class: "update-options" };
const _hoisted_8 = { class: "header" };
const _hoisted_9 = { class: "update-available" };
const _hoisted_10 = { class: "update-text" };
const _hoisted_11 = {
    key: 1,
    class: "update-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_Icon = _resolveComponent("Icon");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_AppForm = _resolveComponent("AppForm");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        "modal-id": _ctx.modalId,
        header: _ctx.translate(_ctx.domainTranslationPrefix('header', true)),
        class: "entity-connect-modal",
        showCloseIcon: false
    }, {
        default: _withCtx(() => [
            _createVNode(_component_AppForm, {
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                onIsValid: _cache[1] || (_cache[1] = ($event) => (_ctx.isValid = $event)),
                errors: _ctx.errors
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("p", {
                            innerHTML: _ctx.getDescription()
                        }, null, 8, _hoisted_2)
                    ]),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_InputLabel, {
                                required: "",
                                disabled: _ctx.isConnected
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("verification-code")), 1)
                                ]),
                                _: 1
                            }, 8, ["disabled"]),
                            _createVNode(_component_InputText, {
                                name: "connectingId",
                                disabled: _ctx.isConnected,
                                required: "",
                                "exact-length": 10,
                                placeholder: _ctx.inputPlaceholder
                            }, null, 8, ["disabled", "placeholder"]),
                            _createVNode(_component_FormError, { name: "connectingId" })
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_Icon, {
                                    class: "connection-info-icon",
                                    name: "info",
                                    variant: "outlined"
                                }),
                                _createElementVNode("div", _hoisted_4, [
                                    (_ctx.isConnected)
                                        ? (_openBlock(), _createElementBlock("p", {
                                            key: 0,
                                            innerHTML: _ctx.translate(_ctx.domainTranslationPrefix('info-disconnect', true))
                                        }, null, 8, _hoisted_5))
                                        : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.translate("info-connect")), 1))
                                ])
                            ])
                        ]),
                        _: 1
                    }),
                    (_ctx.isConnected)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_7, [
                                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.translate("update-options")), 1),
                                        _createVNode(_component_RadioButton, {
                                            "model-value": _ctx.model.updateOption,
                                            "truthy-label": "modular-content.components.connect-modal.auto-sync",
                                            "falsy-label": "modular-content.components.connect-modal.manual-update",
                                            disabled: ""
                                        }, null, 8, ["model-value"])
                                    ])
                                ]),
                                _: 1
                            }),
                            _createElementVNode("div", _hoisted_9, [
                                (_ctx.isUpdateAvailable && !_ctx.entityUpdated)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createElementVNode("div", _hoisted_10, [
                                            _createElementVNode("span", null, _toDisplayString(_ctx.translate("update-available")) + ": ", 1),
                                            _createElementVNode("span", null, " (" + _toDisplayString(_ctx.$utils.getDateValue(_ctx.connectedEntity?.lastUpdateInConnectionSource, "YYYY-MM-DD HH:mm")) + ") ", 1)
                                        ]),
                                        _createVNode(_component_AppButton, {
                                            onClick: _ctx.updateConnection,
                                            loading: _ctx.isLoadingData,
                                            class: "p-button-secondary update-button"
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_Icon, { name: "sync" }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.translate("buttons.update")), 1)
                                            ]),
                                            _: 1
                                        }, 8, ["onClick", "loading"])
                                    ], 64))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                        _createElementVNode("span", null, _toDisplayString(_ctx.translate("no-update-available")), 1),
                                        _createVNode(_component_AppButton, {
                                            disabled: "",
                                            class: "p-button-secondary"
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_Icon, { name: "sync_disabled" })
                                            ]),
                                            _: 1
                                        })
                                    ]))
                            ])
                        ], 64))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            }, 8, ["modelValue", "errors"]),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, {
                        onClick: _ctx.close,
                        class: "p-button-tertiary"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("buttons.close")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"]),
                    (_ctx.isConnected)
                        ? (_openBlock(), _createBlock(_component_AppButton, {
                            key: 0,
                            onClick: _ctx.disconnectMethod,
                            loading: _ctx.isDisconnecting,
                            class: "p-button-danger"
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("buttons.disconnect")), 1)
                            ]),
                            _: 1
                        }, 8, ["onClick", "loading"]))
                        : (_openBlock(), _createBlock(_component_AppButton, {
                            key: 1,
                            onClick: _ctx.connectMethod,
                            loading: _ctx.isConnecting,
                            disabled: !_ctx.isValid
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("buttons.connect")), 1)
                            ]),
                            _: 1
                        }, 8, ["onClick", "loading", "disabled"]))
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
