import _ from "lodash";
import { merge } from "@/app/shared/functions/merge";
import { clone } from "@/app/shared/functions/clone";
import fallback from "@/assets/i18n/en.yml";
import CryptoJS from "crypto-js";
const createHash = (key, params) => {
    const p = _.isEmpty(params) ? "" : JSON.stringify(params);
    return CryptoJS.MD5([key, p].join("")).toString();
};
export const translateFunctionFactory = (templateService, cache) => (translations, key, params = {}) => {
    /**
     * WORKAROUND:
     * This function gets called hundreds of times with any action in the frontend,
     * causing a lot of performance issues. Doing the actual translation takes between
     * 5 and 10 milliseconds on average, so some actions can take up to a second
     * or more just due to translations.
     * Retrieving a translation from the cache takes less than 1 millisecond, even if
     * most of the translations are in the cache. (which requires somewhere on the order
     * of 100kB)
     * IMPORTANT: There is a small chance for a hash collision, which could lead to
     * an incorrect translation.
     */
    const hash = createHash(key, params);
    let translation = cache.get(hash);
    if (translation !== undefined) {
        return translation;
    }
    translation =
        templateService.eval(_.get(translations, key), merge(clone(translations), clone(params))) ??
            /**
             * WORKAROUND:
             * The backend doesn't set up keys and fallbacks for all languages, so we have to
             * handle missing keys.
             */
            templateService.eval(_.get(fallback, key), merge(clone(fallback), clone(params))) ??
            key;
    cache.set(hash, translation);
    return translation;
};
