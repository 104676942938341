import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a02f33da"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "opening-hour-input" };
const _hoisted_2 = { class: "to" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Calendar = _resolveComponent("Calendar");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Calendar, {
            modelValue: _ctx.model.from,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_ctx.updateValue('from', $event))),
            timeOnly: true,
            hourFormat: "24",
            stepMinute: 10,
            disabled: _ctx.disabled
        }, null, 8, ["modelValue", "disabled"]),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate("phrases.to")), 1),
        _createVNode(_component_Calendar, {
            modelValue: _ctx.model.to,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => (_ctx.updateValue('to', $event))),
            timeOnly: true,
            hourFormat: "24",
            stepMinute: 10,
            disabled: _ctx.disabled
        }, null, 8, ["modelValue", "disabled"])
    ]));
}
