import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-30772bd0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "d-flex"
};
const _hoisted_2 = { class: "col-3 col-xl-2 d-flex gap-3 align-items-center" };
const _hoisted_3 = { class: "col-4 col-xxl-3" };
const _hoisted_4 = {
    key: 0,
    class: "col-3 col-xl-2 d-flex justify-content-center gap-3 align-items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createBlock(_component_AppForm, {
        model: _ctx.model,
        errors: _ctx.errors
    }, {
        default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (allowedHeatingMediumData, index) => {
                return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "allowed-heating-medium"
                }, [
                    allowedHeatingMediumData
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createElementVNode("div", _hoisted_2, [
                                _createVNode(_component_AppCheckbox, {
                                    modelValue: allowedHeatingMediumData.isEnabled,
                                    "onUpdate:modelValue": ($event) => ((allowedHeatingMediumData.isEnabled) = $event)
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.getDictionaryValue(allowedHeatingMediumData.heatingMedium, _ctx.heatingMediumDictionary, "HeatingMedium")), 1)
                                    ]),
                                    _: 2
                                }, 1032, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_InputNumber, {
                                    disabled: !allowedHeatingMediumData.isEnabled,
                                    mode: "decimal",
                                    maxFractionDigits: 1,
                                    modelValue: allowedHeatingMediumData.maxHeatingMediumTemperature,
                                    "onUpdate:modelValue": ($event) => ((allowedHeatingMediumData.maxHeatingMediumTemperature) = $event),
                                    name: `${_ctx.name}[${index}].maxHeatingMediumTemperature`,
                                    placeholder: _ctx.translate('maximun-input-temperature')
                                }, null, 8, ["disabled", "modelValue", "onUpdate:modelValue", "name", "placeholder"]),
                                _createVNode(_component_FormError, {
                                    name: `${_ctx.name}[${index}].maxHeatingMediumTemperature`
                                }, null, 8, ["name"])
                            ]),
                            (allowedHeatingMediumData.isEnabled)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    _createVNode(_component_RadioButton, {
                                        modelValue: allowedHeatingMediumData.maxHeatingMediumTemperatureUnit,
                                        "onUpdate:modelValue": ($event) => ((allowedHeatingMediumData.maxHeatingMediumTemperatureUnit) = $event),
                                        dictionary: _ctx.tempTypeDictionary,
                                        dictionaryId: "TemperatureUnit"
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "dictionary"])
                                ]))
                                : _createCommentVNode("", true)
                        ]))
                        : _createCommentVNode("", true)
                ]));
            }), 128))
        ]),
        _: 1
    }, 8, ["model", "errors"]));
}
