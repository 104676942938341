import { WordType } from "@/app/translation/types/word-type.type";
import _ from "lodash";
import { sentenceCase } from "change-case";
const pipes = {
    sentence: (input) => sentenceCase(input, {
        stripRegexp: new RegExp(""),
    }),
    lower: (input) => input.toLowerCase(),
    upper: (input) => input.toUpperCase(),
    join: (input, ...args) => input.join(args[0]),
};
const renderDynamicVariable = (parsed, args) => {
    const key = parsed
        .filter((it) => it.type === WordType.DynamicKey)
        .map((it) => it.raw)
        .join("");
    return _.get(args, key);
};
const renderPipe = (parsed) => {
    let index = 1;
    const name = parsed[0].raw.trim();
    const args = [];
    while (index < parsed.length) {
        const word = parsed[index];
        if (word.type === WordType.String) {
            args.push(word.raw);
        }
        if (word.type === WordType.Text) {
            args.push(parseInt(word.raw));
        }
        index++;
    }
    const pipe = _.get(pipes, name);
    if (!pipe) {
        throw new Error(`Unknown pipe ${name}.`);
    }
    return (input) => {
        return pipe(input, ...args);
    };
};
const renderVariable = (parsed, args) => {
    let index = 0;
    const keyBuffer = [];
    const pipes = [];
    while (index < parsed.length) {
        const word = parsed[index];
        if (word.type === WordType.Key) {
            keyBuffer.push(word.raw.trim());
        }
        if (word.type === WordType.DynamicKeyStart) {
            index++;
            const [buffer, next] = selectWordsUntil(WordType.DynamicKeyEnd, parsed, index);
            keyBuffer.push(renderDynamicVariable(buffer, args));
            index = next;
            continue;
        }
        if (word.type === WordType.FunctionName) {
            const [buffer, next] = selectWordsUntil(WordType.FunctionArgEnd, parsed, index);
            pipes.push(renderPipe(buffer));
            index = next;
            continue;
        }
        index++;
    }
    const key = keyBuffer.join("");
    let value = _.get(args, key);
    for (const pipe of pipes) {
        value = pipe(value);
    }
    return value;
};
const selectWordsUntil = (type, parsed, start) => {
    let index = start;
    const buffer = [];
    while (index < parsed.length) {
        const word = parsed[index];
        if (word.type === type) {
            return [buffer, index + 1];
        }
        else {
            buffer.push(word);
        }
        index++;
    }
    return [buffer, parsed.length];
};
export const renderTemplate = (parsed, args) => {
    const resultBuffer = [];
    let index = 0;
    while (index < parsed.length) {
        const word = parsed[index];
        if (word.type === WordType.VariableStart) {
            index++;
            const [buffer, next] = selectWordsUntil(WordType.VariableEnd, parsed, index);
            resultBuffer.push(renderVariable(buffer, args));
            index = next;
            continue;
        }
        if (word.type === WordType.Text) {
            resultBuffer.push(word.raw);
        }
        index++;
    }
    return resultBuffer.join("");
};
