import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d8aab8d4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "top-navigation" };
const _hoisted_2 = ["href"];
const _hoisted_3 = ["href"];
const _hoisted_4 = ["href"];
const _hoisted_5 = ["href"];
const _hoisted_6 = { class: "menu-item-with-label" };
const _hoisted_7 = { class: "label" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_MenuItem = _resolveComponent("MenuItem");
    const _component_OverlayPanel = _resolveComponent("OverlayPanel");
    return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        (_ctx.hasPermission)
            ? (_openBlock(), _createBlock(_component_MenuItem, {
                key: 0,
                class: "background-square",
                icon: "help_outline",
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.toggleDropdown('legalLinksMenu', $event)))
            }))
            : _createCommentVNode("", true),
        _createVNode(_component_OverlayPanel, { ref: "legalLinksMenu" }, {
            default: _withCtx(() => [
                _createElementVNode("div", {
                    class: "legal-links-menu-content",
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.toggleDropdown('legalLinksMenu', $event)))
                }, [
                    _createElementVNode("a", {
                        href: _ctx.translate('legal-links-menu.faq.link'),
                        target: "_blank",
                        rel: "noopener noreferrer"
                    }, [
                        _createVNode(_component_MenuItem, {
                            label: _ctx.translate('legal-links-menu.faq.value')
                        }, null, 8, ["label"])
                    ], 8, _hoisted_2),
                    _createElementVNode("a", {
                        href: _ctx.translate('legal-links-menu.imprint.link'),
                        target: "_blank",
                        rel: "noopener noreferrer"
                    }, [
                        _createVNode(_component_MenuItem, {
                            label: _ctx.translate('legal-links-menu.imprint.value')
                        }, null, 8, ["label"])
                    ], 8, _hoisted_3),
                    _createElementVNode("a", {
                        href: _ctx.translate('legal-links-menu.privacy-policy.link'),
                        target: "_blank",
                        rel: "noopener noreferrer"
                    }, [
                        _createVNode(_component_MenuItem, {
                            label: _ctx.translate('legal-links-menu.privacy-policy.value')
                        }, null, 8, ["label"])
                    ], 8, _hoisted_4),
                    _createVNode(_component_MenuItem, {
                        label: _ctx.translate('legal-links-menu.cookie-policy.value'),
                        onClick: _ctx.openCookieSettings
                    }, null, 8, ["label", "onClick"]),
                    _createElementVNode("a", {
                        href: _ctx.translate('legal-links-menu.contact.link'),
                        target: "_blank",
                        rel: "noopener noreferrer"
                    }, [
                        _createVNode(_component_MenuItem, {
                            label: _ctx.translate('legal-links-menu.contact.value')
                        }, null, 8, ["label"])
                    ], 8, _hoisted_5)
                ])
            ]),
            _: 1
        }, 512),
        _createVNode(_component_MenuItem, {
            class: "background",
            icon: "person",
            onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.toggleDropdown('userMenu', $event)))
        }),
        _createVNode(_component_OverlayPanel, { ref: "userMenu" }, {
            default: _withCtx(() => [
                _createElementVNode("div", {
                    class: "user-menu-content",
                    onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.toggleDropdown('userMenu', $event)))
                }, [
                    _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_MenuItem, {
                            icon: "language",
                            label: _ctx.translate('menu-items.language'),
                            onClick: _ctx.openChangeLanguageModal
                        }, null, 8, ["label", "onClick"]),
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.language), 1)
                    ]),
                    _createVNode(_component_MenuItem, {
                        icon: "logout",
                        label: _ctx.translate('menu-items.logout'),
                        onClick: _ctx.logout
                    }, null, 8, ["label", "onClick"])
                ])
            ]),
            _: 1
        }, 512)
    ]));
}
