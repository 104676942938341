import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { HttpRequestInterceptorToken } from "@/app/http/tokens/http-request-interceptor.token";
import _ from "lodash";
/**
 * WORKAROUND:
 * The backend accepts inconsistent values for the `Accept-Language` header, so we need to adjust the values we send to
 * specific endpoints to be able to define languages consistently.
 */
let AcceptLanguageInterceptorService = class AcceptLanguageInterceptorService {
    languageMap = {
        de: "de",
        en: "en",
        pl: "pl",
        es: "es",
        fr: "fr",
        it: "it",
        ro: "ro",
    };
    interceptRequest(request, next) {
        if (!_.has(request.headers, "Accept-Language")) {
            return next(request);
        }
        const language = _.get(request.headers, "Accept-Language");
        request.headers = {
            ...request.headers,
            "Accept-Language": this.languageMap[language] ?? language,
        };
        return next(request);
    }
};
AcceptLanguageInterceptorService = __decorate([
    service({ token: HttpRequestInterceptorToken })
], AcceptLanguageInterceptorService);
export { AcceptLanguageInterceptorService };
