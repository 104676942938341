import { createModule } from "@/di/module";
import { TranslationService } from "@/app/translation/services/translation.service";
import { DictionaryTranslationService } from "@/app/translation/services/dictionary-translation.service";
import { AppInitServiceToken } from "@/app/shared/tokens/app-init-service.token";
import { TemplateService } from "@/app/translation/services/template.service";
import { AcceptLanguageInterceptorService } from "@/app/translation/services/accept-language-interceptor.service";
import { TranslationCache } from "@/app/translation/translation.cache";
import { TranslationCacheToken } from "@/app/translation/tokens/translation-cache.token";
import { TranslateFunctionFactoryToken } from "@/app/translation/tokens/translate-function-factory.token";
import { translateFunctionFactory } from "@/app/translation/factories/translate-function.factory";
export const TranslationModule = createModule({
    providers: [
        TranslationService,
        DictionaryTranslationService,
        TemplateService,
        AcceptLanguageInterceptorService,
        { token: AppInitServiceToken, useToken: TranslationService },
        { token: AppInitServiceToken, useToken: DictionaryTranslationService },
        { token: TranslationCacheToken, useValue: new TranslationCache() },
        { token: TranslateFunctionFactoryToken, useValue: translateFunctionFactory },
    ],
});
