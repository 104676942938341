var _a;
import { __decorate, __metadata, __param } from "tslib";
import axios from "axios";
import { service } from "@/di/decorators/service.decorator";
import { injectAll } from "@/di/decorators/inject-all.decorator";
import { RouterService } from "@/app/shared/services/router.service";
import { applyDomainBaseUrlParams } from "@/app/modular-content/modules/functions/domain-base-url-params.function";
import { HttpErrorInterceptorToken } from "@/app/http/tokens/http-error-interceptor.token";
import { HttpRequestInterceptorToken } from "@/app/http/tokens/http-request-interceptor.token";
import { HttpResponseInterceptorToken } from "@/app/http/tokens/http-response-interceptor.token";
let HttpClientService = class HttpClientService {
    requestInterceptors;
    responseInterceptors;
    errorInterceptors;
    routerService;
    axiosInstance;
    baseUrl = process.env.VUE_APP_API_BASE_URL;
    constructor(requestInterceptors, responseInterceptors, errorInterceptors, routerService) {
        this.requestInterceptors = requestInterceptors;
        this.responseInterceptors = responseInterceptors;
        this.errorInterceptors = errorInterceptors;
        this.routerService = routerService;
        this.axiosInstance = axios.create();
    }
    async dispatch(config) {
        try {
            return await this.axiosInstance.request(config);
        }
        catch (e) {
            this.errorInterceptors.forEach((it) => it.onResponseError(config, e));
            throw e;
        }
    }
    async request(config) {
        const interceptors = this.requestInterceptors.filter((it) => it.interceptRequest !== undefined).reverse();
        const next = async (conf) => {
            if (interceptors.length === 0) {
                return this.dispatch(conf);
            }
            return interceptors.pop()?.interceptRequest(conf, next);
        };
        let response = await next(config);
        for (const it of this.responseInterceptors) {
            response = await it.interceptResponse(config, response);
        }
        return response;
    }
    createUrl(urlOrPath) {
        if (urlOrPath.startsWith("http")) {
            return urlOrPath;
        }
        urlOrPath = applyDomainBaseUrlParams(urlOrPath, this.routerService.getParams());
        return `${this.baseUrl}/${urlOrPath}`;
    }
    async plainRequest(method, url, config, body) {
        return await this.request({
            ...config,
            method,
            url: this.createUrl(url),
            data: body,
        });
    }
    async get(url, config) {
        return (await this.request({
            ...config,
            method: "get",
            url: this.createUrl(url),
        })).data;
    }
    async delete(url, config) {
        return (await this.request({
            ...config,
            method: "delete",
            url: this.createUrl(url),
        })).data;
    }
    async post(url, body, config) {
        return (await this.request({
            ...config,
            method: "post",
            url: this.createUrl(url),
            data: body,
        })).data;
    }
    async put(url, body, config) {
        return (await this.request({
            ...config,
            method: "put",
            url: this.createUrl(url),
            data: body,
        })).data;
    }
    async patch(url, body, config) {
        return (await this.request({
            ...config,
            method: "patch",
            url: this.createUrl(url),
            data: body,
        })).data;
    }
};
HttpClientService = __decorate([
    service(),
    __param(0, injectAll(HttpRequestInterceptorToken)),
    __param(1, injectAll(HttpResponseInterceptorToken)),
    __param(2, injectAll(HttpErrorInterceptorToken)),
    __metadata("design:paramtypes", [Array, Array, Array, typeof (_a = typeof RouterService !== "undefined" && RouterService) === "function" ? _a : Object])
], HttpClientService);
export { HttpClientService };
